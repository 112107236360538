import { graphql, Link, StaticQuery } from "gatsby";
import React from "react";
import * as styles from "./partners.module.scss";
import Image from "../image/image";

const Partners = () => (
  <StaticQuery
    query={graphql`
      query PartnersQuery {
        partners: allSanityAxeptiaPartners {
          nodes {
            title
            axeptiaPartners {
              name
              logo {
                alt
                asset {
                  _id
                }
              }
            }
          }
        }
      }
    `}
    render={data => {
      const partners = data && data.partners.nodes[0];

      if (partners.axeptiaPartners) {
        return (
          <div className={styles.wrapper}>
            <ul className={styles.container}>
              {partners.axeptiaPartners?.length > 0 &&
                partners.axeptiaPartners?.map((partner, i) => (
                  <li
                    key={`customer-${i}`}
                    data-sal="slide-up"
                    data-sal-delay="3000"
                    data-sal-easing="ease"
                    data-sal-duration="1000"
                  >
                    {partner.logo && partner.logo.asset && (
                      <Image
                        asset={partner.logo.asset}
                        width={258}
                        alt={partner.logo.alt}
                        fit={"fill"}
                      ></Image>
                    )}
                  </li>
                ))}
            </ul>
            <Link to="/get-started" className="secondary-link-btn uppercase">
              Join the others
            </Link>
          </div>
        );
      } else {
        return null;
      }
    }}
  />
);

export default Partners;
