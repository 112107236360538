import { Link } from "gatsby";
import React from "react";
import ProjectPreview from "./project-preview";

import * as styles from "./project-preview-grid.module.scss";

function ProjectPreviewGrid(props) {
  console.log("news", props);
  const posts = props.browseMoreHref ? props.nodes.slice(0, 3) : props.nodes;
  return (
    <div className={styles.root}>
      {props.title && <h3 className={styles.headline}>{props.title}</h3>}
      <ul className={styles.grid}>
        {posts &&
          posts.map((node, i) => (
            <li key={`project-${i}`}>
              <ProjectPreview {...node} />
            </li>
          ))}
      </ul>
      {props.browseMoreHref && props.nodes.length > 3 && (
        <div className={styles.browseMoreNav}>
          <Link className="secondary-link-btn" to={props.browseMoreHref}>
            Browse more
          </Link>
        </div>
      )}
    </div>
  );
}

ProjectPreviewGrid.defaultProps = {
  title: "",
  nodes: [],
  browseMoreHref: ""
};

export default ProjectPreviewGrid;
