import { Link } from "gatsby";
import { format, formatDistance, differenceInDays } from "date-fns";
import React from "react";
import Card from "@mui/material/Card";
import * as styles from "./project-preview.module.scss";
import Image from "./image/image";

function ProjectPreview(props) {
  return (
    <Link className={styles.root} to={`/project/${props.slug.current}`}>
      <Card className={styles.card}>
        {props.mainImage && props.mainImage.asset ? (
          <div className={styles.mainImage}>
            {props.isNorwegian && (
              <div className={styles.badge}>
                <img
                  src="/standard_norges-flagg.png"
                  alt="norwegian badge"
                  style={{ height: "auto", width: "30px" }}
                  width={30}
                ></img>
              </div>
            )}
            <Image
              asset={props.mainImage.asset}
              width={408}
              alt={props.mainImage.alt}
              fit={"fill"}
            ></Image>
          </div>
        ) : (
          <div className={styles.mainImage}>
            <img
              style={{ height: "90px", width: "auto" }}
              width={90}
              src="/Axeptia-logo-only.png"
              alt="Axptia logo"
            />
          </div>
        )}
        <div className={styles.content}>
          {props._updatedAt && (
            <div className={styles.publishedAt}>
              {differenceInDays(new Date(props._updatedAt), new Date()) > 3
                ? formatDistance(new Date(props._updatedAt), new Date())
                : format(new Date(props._updatedAt), "MMMM do yyyy")}
            </div>
          )}
          <p className={styles.title}>{props.title}</p>
          {props.categories?.length > 0 && (
            <ul className={styles.categories}>
              {props.categories.map((categorie, i) => (
                <li key={`categorie-${i}`}>{categorie.title}</li>
              ))}
            </ul>
          )}
        </div>
      </Card>
    </Link>
  );
}

export default ProjectPreview;
