// extracted by mini-css-extract-plugin
export var root = "project-preview-module--root--fD4aq";
export var card = "project-preview-module--card--0F0Kz";
export var title = "project-preview-module--title--TnL06";
export var content = "project-preview-module--content--ZV3Hj";
export var badge = "project-preview-module--badge--r7DKD";
export var publishedAt = "project-preview-module--publishedAt--7fKGv";
export var mainImage = "project-preview-module--mainImage--Jq0qY";
export var leadMediaThumb = "project-preview-module--leadMediaThumb--igVI-";
export var excerpt = "project-preview-module--excerpt--2ea4x";
export var categories = "project-preview-module--categories--jZpFA";